import { privateApi } from '@/services/privateApi';
import {
  FarmInput,
  FarmOutput,
  FarmPartialInput,
  FarmWithAdditionalInfoListOutput,
  FindFarmParams,
} from '@fibersail/private-api';

export const findFarm = (params?: FindFarmParams): Promise<FarmWithAdditionalInfoListOutput> =>
  privateApi().findFarm(params);

export const createFarm = (farmInput: FarmInput): Promise<FarmOutput> => privateApi().createFarm(farmInput);

export const updateFarm = (farmId: string, farmInput: FarmPartialInput): Promise<FarmOutput> =>
  privateApi().updateFarm(farmId, farmInput);

export const deleteFarm = (farmId: string): Promise<void> => privateApi().deleteFarm(farmId);

import { RoutePages } from '@/components/SideNavigation/CommonTypes';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import ErrorPage from '../../error-page';

const LogsDiaryList = lazy(() =>
  import(/* webpackChunkName: "LogsDiary" */ '../list').then((module) => ({ default: module.LogsDiaryList }))
);

export const logsDiaryRouter: RouteObject[] = [
  {
    path: RoutePages.LOGS_DIARY,
    element: <LogsDiaryList />,
    errorElement: <ErrorPage />,
  },
];

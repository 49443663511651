import { RoutePages } from '@/components/SideNavigation/CommonTypes';
import ErrorPage from '@/pages/error-page';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const FarmsPage = lazy(() => import(/* webpackChunkName: "Farms" */ '.').then((module) => ({ default: module.Farms })));
const FarmsList = lazy(() =>
  import(/* webpackChunkName: "Farms" */ './list').then((module) => ({ default: module.FarmsListPage }))
);
const FarmCreate = lazy(() =>
  import(/* webpackChunkName: "Farms" */ './create').then((module) => ({ default: module.FarmCreate }))
);
const FarmDetails = lazy(() => import(/* webpackChunkName: "Farms" */ './details'));
const FarmEdit = lazy(() =>
  import(/* webpackChunkName: "Farms" */ './edit').then((module) => ({ default: module.FarmEdit }))
);
const TurbineCreate = lazy(() =>
  import(/* webpackChunkName: "Farms" */ './turbine/create').then((module) => ({ default: module.TurbineCreate }))
);
const TurbineDetails = lazy(() => import(/* webpackChunkName: "Farms" */ './turbine/details'));
const TurbineEdit = lazy(() =>
  import(/* webpackChunkName: "Farms" */ './turbine/edit').then((module) => ({ default: module.TurbineEdit }))
);

export const farmsRouter: RouteObject[] = [
  {
    path: RoutePages.FARMS,
    element: <FarmsPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <FarmsList />,
        errorElement: <ErrorPage />,
      },
      {
        path: RoutePages.CREATE,
        element: <FarmCreate />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePages.VIEW}/:farmId`,
        element: <FarmDetails />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePages.VIEW}/:farmId/${RoutePages.CREATE_TURBINE}`,
        element: <TurbineCreate />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePages.VIEW}/:farmId/${RoutePages.TURBINE}/:wtId`,
        element: <TurbineDetails />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePages.VIEW}/:farmId/${RoutePages.EDIT_TURBINE}/:wtId`,
        element: <TurbineEdit />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RoutePages.EDIT}/:farmId`,
        element: <FarmEdit />,
        errorElement: <ErrorPage />,
      },
    ],
  },
];

import { privateUserApi } from '@/services/privateUserApi';
import {
  FindUserParams,
  ResetUserPasswordParams,
  UserListOutput,
  UserOutput,
  UserPartialInput,
  UserRequestInput,
} from '@fibersail/user-private-api';

export const createUser = async (user: UserRequestInput): Promise<UserOutput> => privateUserApi().createUser(user);

export const findUser = async (params?: FindUserParams): Promise<UserListOutput> => privateUserApi().findUser(params);

export const updateUser = async (userId: string, params: UserPartialInput): Promise<UserOutput> =>
  privateUserApi().updateUser(userId, params);

export const deleteUser = async (userId: string): Promise<void> => privateUserApi().deleteUser(userId);

export const resetUserPassword = async (params: ResetUserPasswordParams): Promise<void> =>
  privateUserApi().resetUserPassword(params);

import { signOut } from '@/api';
import { UserPartialInputRole } from '@fibersail/user-private-api';

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const passwordRegex =
  /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;

export const storeAccessToken = (token: string) => {
  sessionStorage.setItem('accessToken', token);
};

export const clearAccessToken = () => {
  sessionStorage.removeItem('accessToken');
};

export const storeIdToken = (token: string) => {
  sessionStorage.setItem('idToken', token);
};

export const clearIdToken = () => {
  sessionStorage.removeItem('idToken');
};

export const getAccessToken = () => sessionStorage.getItem('accessToken');

export const getIdToken = () => sessionStorage.getItem('idToken');

export const validateStatus = (status: number): boolean => {
  if (status === 401) {
    signOut();
    return false;
  }
  return status >= 200 && status <= 299;
};

export const USER_ROLE_I18N = {
  [UserPartialInputRole.AccountAdmin]: 'Account admin',
  [UserPartialInputRole.AccountDeviceManager]: 'Account device manager',
  [UserPartialInputRole.AccountFarmAdmin]: 'Account farm admin',
  [UserPartialInputRole.AccountOp]: 'Account op',
  [UserPartialInputRole.SystemAdmin]: 'System admin',
};

import { keyframes, mergeStyleSets } from '@fluentui/react';

const rotation = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' },
});
export const loader = (): { animation: string } => ({
  animation: `${rotation} 2s linear infinite`,
});

interface ICustomLoadingClassNames {
  content: string;
}
export const getCustomLoadingClassNames = (): ICustomLoadingClassNames =>
  mergeStyleSets({
    content: {
      height: '100vh',
      width: '100vw',
    },
  });

import { RoutePages } from '@/components/SideNavigation/CommonTypes';
import ErrorPage from '@/pages/error-page';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const AuthPage = lazy(() =>
  import(/* webpackChunkName: "Auth" */ './pages').then((module) => ({ default: module.AuthView }))
);

const SignInView = lazy(() =>
  import(/* webpackChunkName: "Auth" */ './pages/SignIn').then((module) => ({ default: module.SignInView }))
);

export const authRouter: RouteObject[] = [
  {
    path: RoutePages.AUTH,
    element: <AuthPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <SignInView />,
        errorElement: <ErrorPage />,
      },
    ],
  },
];

import { privateApi } from '@/services/privateApi';
import {
  FindWTModelParams,
  FindWTParams,
  WTInput,
  WTListOutput,
  WTModelInput,
  WTModelListOutput,
  WTModelOutput,
  WTModelPartialInput,
  WTOutput,
  WTPartialInput,
} from '@fibersail/private-api';

export const findWT = (params: FindWTParams): Promise<WTListOutput> => privateApi().findWT(params);

export const createWT = (wtInput: WTInput): Promise<WTOutput> => privateApi().createWT(wtInput);

export const updateWT = (wtId: string, wTPartialInput: WTPartialInput): Promise<WTOutput> =>
  privateApi().updateWT(wtId, wTPartialInput);

export const findWTModel = (params?: FindWTModelParams): Promise<WTModelListOutput> => privateApi().findWTModel(params);

export const createWTModel = (wtModelInput: WTModelInput): Promise<WTModelOutput> =>
  privateApi().createWTModel(wtModelInput);

export const updateWTModel = (wtModelId: string, wTModelPartialInput: WTModelPartialInput): Promise<WTModelOutput> =>
  privateApi().updateWTModel(wtModelId, wTModelPartialInput);

export const deleteWTModel = (wtModelId: string): Promise<void> => privateApi().deleteWTModel(wtModelId);

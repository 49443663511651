import { signIn, signOut } from '@/api';
import { InnerProps, LoginUser } from '@/entities';
import { userPool } from '@/utils/auth';
import { clearAccessToken, clearIdToken, storeAccessToken, storeIdToken } from '@/utils/general';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { createContext, useContext, useState } from 'react';

export type UserContextProps = {
  isLoading: boolean;
  setIsLoading: (state: boolean) => void;

  signIn: (user: LoginUser) => Promise<any>;
  signOut: () => Promise<void>;
  checkAuth: () => Promise<boolean>;

  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;

  userSession?: CognitoUserSession;
  setUserSession: (userSession?: CognitoUserSession) => void;
};

export const UserContextDefaultValues = {
  isLoading: false,
  setIsLoading: (state: boolean) => state,

  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  checkAuth: () => Promise.resolve(false),

  isAuthenticated: false,
  setIsAuthenticated: () => undefined,

  userSession: undefined,
  setUserSession: () => undefined,
};

export const Context = createContext<UserContextProps>(UserContextDefaultValues);

export const UserProvider = ({ children }: InnerProps) => {
  const [userSession, setUserSession] = useState<CognitoUserSession>();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkAuth = (): Promise<boolean> => {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      const currentUser = userPool.getCurrentUser();
      if (currentUser) {
        currentUser.getSession((err: null, session: CognitoUserSession) => {
          if (err) {
            setIsAuthenticated(false);
            clearAccessToken();
            clearIdToken();
            setUserSession(undefined);
            setIsLoading(false);
            reject(err);
          }
          const isValid = session.isValid();
          setIsAuthenticated(isValid);
          storeAccessToken(session.getAccessToken().getJwtToken());
          storeIdToken(session.getIdToken().getJwtToken());
          setUserSession(session);
          setIsLoading(false);

          resolve(isValid);
        });
      } else {
        setIsAuthenticated(false);
        setIsLoading(false);
        reject();
      }
    });
  };

  const customSignIn = (user: LoginUser): Promise<CognitoUserSession> =>
    signIn(user).then((res) => {
      setUserSession(res);
      return res;
    });

  return (
    <Context.Provider
      value={{
        isLoading,
        setIsLoading,
        signIn: customSignIn,
        checkAuth,
        isAuthenticated,
        setIsAuthenticated,
        userSession,
        setUserSession,
        signOut,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext<UserContextProps>(Context);

  if (!context) {
    throw new Error('useAuthContext must be used within an AuthContext.');
  }

  return context;
};

import { RoutePages } from '@/components/SideNavigation/CommonTypes';
import ErrorPage from '@/pages/error-page';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Clients = lazy(() =>
  import(/* webpackChunkName: "Clients" */ '../').then((module) => ({ default: module.Clients }))
);
const ClientsList = lazy(() =>
  import(/* webpackChunkName: "Clients" */ '../list').then((module) => ({ default: module.ClientsList }))
);
const ClientCreate = lazy(() =>
  import(/* webpackChunkName: "Clients" */ '../create').then((module) => ({ default: module.ClientCreate }))
);
const ClientDetails = lazy(() =>
  import(/* webpackChunkName: "Clients" */ '../details').then((module) => ({ default: module.ClientDetails }))
);
const ClientEdit = lazy(() =>
  import(/* webpackChunkName: "Clients" */ '../edit').then((module) => ({ default: module.ClientEdit }))
);

export const clientsRouter: RouteObject[] = [
  {
    path: RoutePages.CLIENTS,
    element: <Clients />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <ClientsList />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'create',
        element: <ClientCreate />,
        errorElement: <ErrorPage />,
      },
      {
        path: ':clientId',
        element: <ClientDetails />,
        errorElement: <ErrorPage />,
      },
      {
        path: ':clientId/edit',
        element: <ClientEdit />,
        errorElement: <ErrorPage />,
      },
    ],
  },
];

import { RoutePages } from '@/components/SideNavigation/CommonTypes';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import ErrorPage from '../../error-page';

const SettingsPage = lazy(() =>
  import(/* webpackChunkName: "Settings" */ '../pages').then((module) => ({ default: module.SettingsPage }))
);

export const settingsRouter: RouteObject[] = [
  {
    path: RoutePages.SETTINGS,
    element: <SettingsPage />,
    errorElement: <ErrorPage />,
  },
];

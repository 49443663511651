import { LoginUser } from '@/entities';
import { userPool } from '@/utils/auth';
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

export const signIn = (user: LoginUser): Promise<CognitoUserSession> => {
  const cognitoUser = new CognitoUser({ Username: user.username, Pool: userPool });

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(new AuthenticationDetails({ Username: user.username, Password: user.password }), {
      onSuccess: (result) => resolve(result),
      onFailure: (err) => {
        let payload = { ...err };
        if (err.code === 'PasswordResetRequiredException') {
          payload = { ...payload, cognitoUser };
        }

        return reject(payload);
      },
      newPasswordRequired: (result) => reject({ ...result, newPasswordRequired: true, cognitoUser }),
    });
  });
};

export const signOut = (): Promise<void> => {
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      cognitoUser.signOut(() => resolve());
    } else {
      reject();
    }
  });
};

export const forgotPassword = (cognitoUser: CognitoUser): Promise<string> => {
  return new Promise((resolve, reject) => {
    return cognitoUser.forgotPassword({
      onSuccess: (data) => {
        resolve(data);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const resetPassword = (code: string, newPassword: string, cognitoUser?: CognitoUser): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      return cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    } else {
      reject();
    }
  });
};

export const completeNewPasswordChallenge = (
  password: string,
  cognitoUser: CognitoUser
): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    cognitoUser.completeNewPasswordChallenge(password, null, {
      onSuccess: (data) => resolve(data),
      onFailure: (err) => reject(err),
    });
  });
};

import { RoutePages } from '@/components/SideNavigation/CommonTypes';
import { RouteObject } from 'react-router-dom';
import ErrorPage from '../../error-page';
import { lazy } from 'react';

const UploadDataList = lazy(() =>
  import(/* webpackChunkName: "UploadData" */ '../list').then((module) => ({ default: module.UploadDataList }))
);

export const uploadDataRouter: RouteObject[] = [
  {
    path: RoutePages.UPLOAD_DATA,
    element: <UploadDataList />,
    errorElement: <ErrorPage />,
  },
];

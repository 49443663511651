import { RoutePages } from '@/components/SideNavigation/CommonTypes';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import ErrorPage from '../../error-page';

const Users = lazy(() => import(/* webpackChunkName: "Users" */ '../').then((module) => ({ default: module.Users })));
const UserListPage = lazy(() =>
  import(/* webpackChunkName: "Users" */ '../list').then((module) => ({ default: module.UserListPage }))
);

export const usersRouter: RouteObject[] = [
  {
    path: RoutePages.USERS,
    element: <Users />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <UserListPage />,
        errorElement: <ErrorPage />,
      },
    ],
  },
];

export enum IconOption {
  FARMS = 'farms',
  UPLOAD_DATA = 'upload-data',
  UPLOADS_DIARY = 'uploads-diary',
  LOGS_DIARY = 'logs-diary',
  CLIENTS = 'clients',
  USERS = 'users',
  SETTINGS = 'settings',
}

export enum RoutePages {
  AUTH = 'auth',
  FARMS = '/',
  UPLOAD_DATA = 'upload-data',
  UPLOADS_DIARY = 'uploads-diary',
  LOGS_DIARY = 'logs-diary',
  CLIENTS = 'clients',
  USERS = 'users',
  SETTINGS = 'settings',

  TURBINE = 'turbine',
  CREATE_TURBINE = 'create-turbine',
  EDIT_TURBINE = 'edit-turbine',

  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export interface IOption {
  label: string;
  icon: IconOption;
  routeTo: RoutePages;
}

export interface SideNavgationButtonProps extends IOption {}

export const menuOptions: IOption[] = [
  {
    label: 'Farms',
    icon: IconOption.FARMS,
    routeTo: RoutePages.FARMS,
  },
  // {
  //   label: 'Upload Data',
  //   icon: IconOption.UPLOAD_DATA,
  //   routeTo: RoutePages.UPLOAD_DATA,
  // },
  // {
  //   label: 'Uploads Diary',
  //   icon: IconOption.UPLOADS_DIARY,
  //   routeTo: RoutePages.UPLOADS_DIARY,
  // },
  // {
  //   label: 'Logs Diary',
  //   icon: IconOption.LOGS_DIARY,
  //   routeTo: RoutePages.LOGS_DIARY,
  // },
  {
    label: 'Clients',
    icon: IconOption.CLIENTS,
    routeTo: RoutePages.CLIENTS,
  },
  {
    label: 'Users',
    icon: IconOption.USERS,
    routeTo: RoutePages.USERS,
  },
  {
    label: 'Settings',
    icon: IconOption.SETTINGS,
    routeTo: RoutePages.SETTINGS,
  },
];

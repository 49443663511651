import { privateApi } from '@/services/privateApi';
import {
  ClientInput,
  ClientListOutput,
  ClientOutput,
  ClientPartialInput,
  FindClientParams,
} from '@fibersail/private-api';

export async function findClient(params?: FindClientParams): Promise<ClientListOutput> {
  return (await privateApi().findClient(params)) as ClientListOutput;
}

export async function createClient(params: ClientInput): Promise<ClientOutput> {
  return (await privateApi().createClient(params)) as ClientOutput;
}

export async function updateClient(clientId: string, params: ClientPartialInput): Promise<ClientOutput> {
  return (await privateApi().updateClient(clientId, params)) as ClientOutput;
}

export async function deleteClient(clientId: string): Promise<void> {
  return (await privateApi().deleteClient(clientId)) as void;
}

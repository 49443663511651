import loading from '@/assets/loading.png';
import { getCustomLoadingClassNames, loader } from './Styling';

export const CustomLoading = (): JSX.Element => {
  const { content } = getCustomLoadingClassNames();

  return (
    <div className={`${content} flex justify-center align-center`}>
      <Loading />
    </div>
  );
};

export const Loading = (): JSX.Element => <img src={loading} alt="Loading" style={loader()} />;

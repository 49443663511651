import { RoutePages } from '@/components/SideNavigation/CommonTypes';
import { RouteObject } from 'react-router-dom';
import ErrorPage from '../../error-page';
import { lazy } from 'react';

const UploadsDiaryList = lazy(() =>
  import(/* webpackChunkName: "UploadsDiary" */ '../list').then((module) => ({ default: module.UploadsDiaryList }))
);

export const uploadsDiaryRouter: RouteObject[] = [
  {
    path: RoutePages.UPLOADS_DIARY,
    element: <UploadsDiaryList />,
    errorElement: <ErrorPage />,
  },
];

import { ReactElement } from 'react';
import { useRouteError } from 'react-router-dom';

type RouteErrorProps = {
  statusText?: string;
  message?: string;
}

export default function ErrorPage(): ReactElement {
  const error = useRouteError() as RouteErrorProps;

  // eslint-disable-next-line
  console.log(error);

  return (
    <div id="error-page" data-testid="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p><i>{error.statusText || error?.message}</i></p>
    </div>
  );
}

import { BladesAvgTipPosition, SelectedView, FarmPerformanceEntry } from '@/entities';
import colors from '@/sass/_colors.scss';

export function generateId(): string {
  return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString();
}

export function getInitials(name: string): string {
  const nameArray = name.split(' ');
  const initials = nameArray.map((word) => word.charAt(0).toUpperCase());
  return initials.join('');
}

type ConvertDataToChartFormatProps = {
  data: any;
  propertyName: keyof FarmPerformanceEntry;
  label: string;
  color: string;
};

type ConvertDataToChartFormatResponseProps = {
  name: string;
  color: string;
  data: FarmPerformanceEntry[];
};

export function convertDataToChartFormat({
  data,
  propertyName,
  label,
  color,
}: ConvertDataToChartFormatProps): ConvertDataToChartFormatResponseProps[] {
  const chartData = data.map((item: any) => {
    const time = new Date(item.time);
    const value = item[propertyName] ?? 0;

    return [
      Date.UTC(time.getUTCFullYear(), time.getUTCMonth(), time.getUTCDate(), time.getUTCHours(), time.getUTCMinutes()),
      value,
    ];
  });

  return [
    {
      name: label,
      color,
      data: chartData,
    },
  ];
}

export const nameof = <T extends {}>(name: keyof T): keyof T => name;

export const getColors = (selectedView: SelectedView): string[] => {
  return selectedView === 'tipPositionFlap'
    ? [colors.greenHaze, colors.primary, colors.flushOrange]
    : [colors.greenLizard, colors.spiroDiscoBall, colors.yellow];
};

export const getColorFromBlades = (blade: BladesAvgTipPosition): string => {
  const avg = (blade.avgTipPositionEdge + blade.avgTipPositionFlap) / 2;

  if (avg > -0.5 && avg < 0.5) return 'green';
  if ((avg > -1 && avg <= -0.5) || (avg < 1 && avg <= 0.5)) return 'yellow';

  return 'red';
};

export const tranformDateToFormattedDate = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();

  return `${day}/${month}/${year}`;
};

import { authRouter } from '@/pages/auth/router';
import { clientsRouter } from '@/pages/clients/router';
import ErrorPage from '@/pages/error-page';
import { farmsRouter } from '@/pages/farms/router';
import { logsDiaryRouter } from '@/pages/logsDiary/router';
import { settingsRouter } from '@/pages/settings/router';
import { uploadDataRouter } from '@/pages/uploadData/router';
import { uploadsDiaryRouter } from '@/pages/uploadsDiary/router';
import { usersRouter } from '@/pages/users/router';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const MenuHeader = lazy(() => import(/* webpackChunkName: "MenuHeader" */ '@/components/MenuHeader'));

export const router: RouteObject[] = [
  ...authRouter,
  {
    path: '/',
    element: <MenuHeader />,
    errorElement: <ErrorPage />,
    children: [
      ...farmsRouter,
      ...uploadDataRouter,
      ...uploadsDiaryRouter,
      ...logsDiaryRouter,
      ...clientsRouter,
      ...usersRouter,
      ...settingsRouter,
    ],
  },
];

import { ThemeProvider, loadTheme } from '@fluentui/react';
import { ReactElement, Suspense, useEffect } from 'react';
import {
  Navigate,
  Route,
  RouteObject,
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
  useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { signOut } from './api';
import { CustomLoading } from './components/Loading';
import { RoutePages } from './components/SideNavigation/CommonTypes';
import { UserProvider, useAuthContext } from './contexts/AuthContext';
import { InnerProps } from './entities';
import { router as splittedRouter } from './router';
import theme from './styles/theme';
import { generateId } from './utils/helpers';

const RequireAuth = ({ children }: InnerProps): JSX.Element => {
  const location = useLocation();
  const { checkAuth, isLoading, isAuthenticated } = useAuthContext();

  useEffect(() => {
    checkAuth().catch(() => {
      signOut()
        .then(() => console.log('sign out ok'))
        .catch((err) => console.log('sign out err', err));
    });
  }, [location.pathname]);

  if (!isLoading && isAuthenticated && location.pathname.includes(RoutePages.AUTH)) {
    return <Navigate to={RoutePages.FARMS} state={{ from: location }} replace />;
  }

  if (!isLoading && !isAuthenticated && !location.pathname.includes(RoutePages.AUTH)) {
    return <Navigate to={`/${RoutePages.AUTH}`} state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

const renderRoute = (route: RouteObject): ReactElement => {
  if (route.children) {
    return (
      <Route
        path={route.path ?? ''}
        key={generateId()}
        element={<RequireAuth>{route.element}</RequireAuth>}
        errorElement={route.errorElement}
      >
        {route.children.map((child) => renderRoute(child))};
      </Route>
    );
  }
  return (
    <Route
      path={route.path ?? ''}
      key={generateId()}
      element={<RequireAuth>{route.element}</RequireAuth>}
      errorElement={route.errorElement}
    />
  );
};

export const App = (): ReactElement => {
  const router = createHashRouter(createRoutesFromElements(splittedRouter.map((route) => renderRoute(route))));

  // Loading custom theme
  loadTheme(theme);

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Suspense fallback={<CustomLoading />}>
          <ToastContainer />
          <RouterProvider router={router} />
        </Suspense>
      </UserProvider>
    </ThemeProvider>
  );
};
